.cardColor {
    background-color: rgba(89,205,251,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardLogo{
    font-size: 52px;
}
.cardColor:active{
    background-color: rgba(155, 21, 233, 0.5);
}
.cardColor:hover{
    background-color: rgba(155, 21, 233, 0.5);
}

.menuColor {
    background-color: rgba(39, 60, 117,1.0);
    color: #FFF;
}


@media only screen and (max-width: 600px) {
        
    .cardLogo{
        font-size: 32px;
    }
    .subtitle{
        font-size: 12px;
    }
    .subTitleH1{
        font-size: 12px;
    }
    .cardColor {
        padding: 0px;
        margin: 0px;
    }
  }