/* .ant-btn-primary {
  background-color: #004d1a;
  color: #ffffff;
  outline: none;
 
}

.ant-btn-primary :hover,
.ant-btn-primary :focus
{
  color: #000000;
  outline: none;
 
}

.ant-btn-danger {
  background-color: #ec7272;
  color: #FFF;
  border: 1px solid rgb(218, 209, 209);
  outline: none;
 
}
@media only screen and (max-width: 992px) {
  .ant-btn-primary {
     padding: 0px 5px;
     font-size: 14px;
  }
}
.ant-btn-danger:hover,
.ant-btn-primary:active  {
  background: #06da18;
  color: #000000;
  border: 1px solid #ffffff;
} */


.ant-page-header {
  background: #fff;
  color: #fff;
}
.ant-page-header-heading-sub-title{
  color:black;
}

 
.ant-card-head-title{
 color:white;

}
.ant-card-head {
border-radius: 50%;
}
.ant-card{
 border-radius: .5em .5em .5em .5em;
}

.ant-layout-content{
background: #ffffff;
}
.ant-layout{
background: #ffffff;

}
.site-layout{
  background-color: #fff;
}

.ant-layout-content{
 
  margin: 0px 5px ;
  padding: 15px;
}

.ant-breadcrumb{
  padding: 10px;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected{
   color: #019fb8;
   border-bottom: 2 px solid #ffffff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #C4F3FE;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
color: #0298af;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
position: absolute;
top: 0;
right: 0;
bottom: 0;
border-right: 3px solid #b68888;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
color: #14ddbc;
}

.ant-menu-item a:hover {
color: #0781e4;
}
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inlibe)
.ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
color: #00adad;
}


