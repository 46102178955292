.ant-layout-sider-trigger{
    background-color: #ffffff;
    color: black;
}
.ant-menu>.ant-menu-light>.ant-menu-inline-collapsed>.ant-menu-root>.ant-menu-vertical{
    position:fixed;
}
.loginContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: rgba(221, 221, 221, 0.849);
}
.gov{
    align-self: center;
    width: 70px;
}
.formLogo{
    align-self: center;
    width: 100px;
}

.loginform{
    align-self: center;
    box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 600px;
    height: 400px;
    background-color: #fff;
}
.author{
    text-align: center;
}
.imageG{
    position: absolute;
    bottom: 20px;
    align-self: center;
    display: flex;
    justify-content: center;
    width: 80%;
}

.bimg{
    width: 80px;
    height: 30px;
    display: flex;
}
.mimg{
    width: 30px;
    height: 30px;
    display: flex;
}
.undpimg{
    width: 20px;
    height: 30px;
    display: flex;
}