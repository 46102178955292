
.panel {
    background: hsla(170, 78%, 78%, 1);
    background: linear-gradient(315deg, hsla(170, 78%, 78%, 1) 0%, hsla(174, 69%, 85%, 1) 50%, hsla(180, 28%, 92%, 1) 100%);
    background: -moz-linear-gradient(315deg, hsla(170, 78%, 78%, 1) 0%, hsla(174, 69%, 85%, 1) 50%, hsla(180, 28%, 92%, 1) 100%);
    background: -webkit-linear-gradient(315deg, hsla(170, 78%, 78%, 1) 0%, hsla(174, 69%, 85%, 1) 50%, hsla(180, 28%, 92%, 1) 100%);
    /* border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%  ; */
    cursor: default;
}

.blackText {
    color: #000;
}

.cardColor {
    /* background-image: url("./productBg.jpg");
    background-repeat: repeat;
    background-size: contain; */
    background-color: rgba(0, 210, 211,1.0);
    cursor: pointer;
}
.cardLogo{
    font-size: 52px;
}
.cardColor:active{
    background-color: rgba(155, 21, 233, 0.5);
}
.cardColor:hover{
    background-color: rgba(155, 21, 233, 0.5);
}

.menuColor {
    background-color: rgba(39, 60, 117,1.0);
    color: #FFF;
}

.nameLogo {
    text-align: center;
}

.nameLogoInner{
    text-align: center;
    background-color: #fff;
    height: 55px;
    width: 55px;
    line-height: 55px;
    border-radius: 50%;
    display: inline-block;
}
