.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn {
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(33, 102, 185);
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }